<template>
  <div v-loading="loading" class="elv-reconciliation-task-container">
    <div class="elv-reconciliation-task-header">
      {{ t('title.reconciliationTasks') }}
    </div>
    <div class="elv-reconciliation-task-header__operation">
      <span class="elv-reconciliation-task-header-count"
        >{{ t('common.total') }}：<span class="number">{{ reconciliationTaskCount }}</span>
      </span>
      <elv-button height="32" width="94" round plain type="primary" @click="onCreateTask"
        ><SvgIcon name="source-add" width="16" height="16" />{{ t('button.addTask') }}</elv-button
      >
    </div>
    <ListTable
      :params="taskParams"
      :table-data="reconciliationStore.reconciliationTaskListType"
      :table-loading="tableLoading"
      @onChangePage="onChangePage"
      @onEditTask="onEditTask"
      @onCopyTask="onCopyTask"
      @on-change-page-size="onChangePageSize"
    />

    <TaskDialog
      v-model:show="showTaskDialog"
      :model="taskDialogModel"
      :currentData="currentTaskData"
      @onResetList="onChangePage(1)"
    />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { emitter } from '@/lib/mitt'
import timezone from 'dayjs/plugin/timezone'
import ListTable from './components/ListTable.vue'
import { useEntityStore } from '@/stores/modules/entity'
import TaskDialog from '../components/TaskDialog.vue'
import { useReconciliationStore } from '@/stores/modules/reconciliation'

dayjs.extend(utc)
dayjs.extend(timezone)
const route = useRoute()
const entityStore = useEntityStore()
const reconciliationStore = useReconciliationStore()

const loading = ref(true)
const tableLoading = ref(false)
const showTaskDialog = ref(false)
const taskDialogModel = ref('add') // add or edit or copy
const currentTaskData: any = ref({})

const taskParams = ref({
  page: 1,
  limit: 20
})

const { t } = useI18n()

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const reconciliationTaskCount = computed(() => {
  return reconciliationStore.reconciliationTaskListType.totalCount || 0
})

/**
 * 获取对账任务列表
 */
const getReconciliationTaskList = async () => {
  try {
    tableLoading.value = true
    await reconciliationStore.fetchReconciliationTaskList(entityId.value, taskParams.value)
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

const onChangePageSize = (limit: number) => {
  taskParams.value.limit = limit
  taskParams.value.page = 1
  getReconciliationTaskList()
}

const onChangePage = (page: number) => {
  taskParams.value.page = page
  getReconciliationTaskList()
}

const reconciliationTaskDataInit = async () => {
  try {
    loading.value = true
    await getReconciliationTaskList()
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

/**
 * 创建对账任务
 */
const onCreateTask = () => {
  taskDialogModel.value = 'add'
  showTaskDialog.value = true
  currentTaskData.value = {}
}

/**
 * 编辑对账任务
 * @param currentData 编辑任务的数据
 */
const onEditTask = (currentData: any) => {
  currentTaskData.value = currentData
  taskDialogModel.value = 'edit'
  showTaskDialog.value = true
}

/**
 * 复制对账任务
 * @param currentData 复制任务的数据
 */
const onCopyTask = (currentData: any) => {
  currentTaskData.value = currentData
  taskDialogModel.value = 'copy'
  showTaskDialog.value = true
}

emitter.on('reconciliationTaskDataInit', reconciliationTaskDataInit)

onBeforeMount(() => {
  if (entityStore.entityDetail?.entityId === entityId.value) {
    reconciliationTaskDataInit()
  }
})

onBeforeUnmount(() => {
  emitter.off('reconciliationTaskDataInit', reconciliationTaskDataInit)
})
</script>

<style lang="scss" scoped>
.elv-reconciliation-task-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.elv-reconciliation-task-header {
  width: 100%;
  color: #000;
  font-family: 'Plus Jakarta Sans';
  font-size: 16px;
  font-weight: 700;
  line-height: 23px;
  padding-left: 20px;
}

.elv-reconciliation-task-header__operation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Plus Jakarta Sans';
  padding: 0px 32px 0px 20px;
  font-size: 12px;
  font-weight: 400;
  margin: 10px 0px 8px;
  color: #838d95;

  .number {
    color: #0e0f11;
  }
}

.elv-reconciliation-task-empty-content {
  margin-top: 180px;

  .elv-result-description {
    margin-top: 12px;

    p {
      color: #000;
      font-family: 'Plus Jakarta Sans';
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}
</style>
