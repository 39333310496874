<template>
  <div class="elv-reconciliation-tasks-list-table">
    <div class="elv-reconciliation-tasks-list-table-box">
      <el-table
        ref="tableListRef"
        v-loading="props.tableLoading"
        :data="props.tableData.list"
        border
        stripe
        height="100%"
        class="elv-reconciliation-tasks-table"
        header-cell-class-name="elv-reconciliation-tasks-table-header__cell"
        header-row-class-name="elv-reconciliation-tasks-table-header"
        row-class-name="elv-reconciliation-tasks-table-row"
        cell-class-name="elv-reconciliation-tasks-table-row__cell"
        @row-click="onCellClicked"
      >
        <el-table-column
          type="index"
          width="40"
          align="center"
          class-name="elv-reconciliation-tasks-table-row__cell-index"
        >
          <template #default="{ $index }">
            <span class="elv-sources-rule-table-row__cell-index">{{
              $index + 1 + (props.params.page - 1) * props.params.limit
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="80" label="ID" class-name="is-number">
          <template #default="{ row }">
            {{ row.taskNo }}
          </template>
        </el-table-column>
        <el-table-column :label="t('report.taskName')" class-name="is-number">
          <template #default="{ row }">
            <span class="elv-sources-rule-table-row__cell-task-name">{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column width="200" :label="t('common.dateRange')">
          <template #default="{ row }">
            <DateTimeFromToCell :fromTime="row.fromDatetime" :endTime="row.toDatetime" format="YYYY/MM/DD" />
          </template>
        </el-table-column>
        <el-table-column width="300" :label="t('common.dataTypes')" class-name="is-type">
          <template #default="{ row }">
            <div class="elv-sources-rule-table-row__cell-data-type">
              <span>{{ currentTaskType(row.dataSetTypeA) }}</span>
              <SvgIcon name="horizontal-arrow" width="12" height="12" :fill="'#AAAFB6'" />
              <span>{{ currentTaskType(row.dataSetTypeB) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="110">
          <template #default="{ row }">
            <p v-if="row?.status === 'DELETING'" class="elv-reconciliation-tasks-table-row__cell-deleting">
              <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />{{ t('report.deleting') }}
            </p>
            <p v-else-if="row?.status === 'MATCHING'" class="elv-reconciliation-tasks-table-row__cell-deleting">
              <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />{{
                t('reconciliation.matching')
              }}
            </p>
            <p v-else-if="row?.status === 'LOADING'" class="elv-reconciliation-tasks-table-row__cell-deleting">
              <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />{{ t('reconciliation.loading') }}
            </p>
            <div v-else class="elv-reconciliation-tasks-table-row__cell-button">
              <SvgIcon name="sources-edit" width="18" height="18" @click="onEditTask(row, $event)" />
              <SvgIcon name="sources-copy" width="18" height="18" @click="onCopyTask(row, $event)" />
              <SvgIcon name="sources-delete" width="18" height="18" @click="onOpenDeleteConfirm(row, $event)" />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <elv-pagination
      class="elv-reconciliation-tasks-list-pagination"
      size-popper-class="elv-reconciliation-tasks-pagination__sizes-popper"
      :limit="props.params.limit"
      :current-page="props.params.page"
      :total="props.tableData.totalCount"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
    <ReconciliationTaskStatusChangeDialog
      v-if="showDeleteTaskDialog"
      v-model:show="showDeleteTaskDialog"
      v-model:agree="agreeRemovedData"
      v-model:loading="deleteTaskLoading"
      :title="t('reconciliation.deleteReconciliationTask')"
      :confirmText="t('button.delete')"
      :cancelText="t('button.cancel')"
      @onConfirmChangeStatus="onDeleteReconciliationTask"
      @onCloseDialog="showDeleteTaskDialog = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus'
import { transformI18n } from '@/i18n/index'
import ReconciliationApi from '@/api/ReconciliationApi'
import { ReconciliationTaskListType } from '#/ReconciliationTypes'
import { DataTypeForReconciliation } from '@/config/reconciliation'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import DateTimeFromToCell from '../../../components/DateTimeFromToCell.vue'
import ReconciliationTaskStatusChangeDialog from '../../components/ReconciliationTaskStatusChangeDialog.vue'

const props = defineProps({
  tableData: {
    type: Object as () => ReconciliationTaskListType,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  params: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const reconciliationStore = useReconciliationStore()
const emit = defineEmits(['onChangePage', 'onChangePageSize', 'onEditTask', 'onCopyTask'])

const tableListRef = ref()
const agreeRemovedData = ref(false)
const deleteTaskLoading = ref(false)
const showDeleteTaskDialog = ref(false)
const overlayDrawerData = reactive({
  currentData: {} as any
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentTaskType = computed(() => (type: string) => {
  const currentType = DataTypeForReconciliation.find((item) => item.value === type)
  return transformI18n(currentType?.label) || ''
})

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const onCellClicked = async (row: any) => {
  if (row.status === 'DELETING') return
  overlayDrawerData.currentData = row
  reconciliationStore.reconciliationTaskDetail = row
  router.push({
    name: 'entity-reconciliation-data-sets',
    params: {
      reconciliationTaskId: row?.reconciliationTaskId
    }
  })
}

const onEditTask = (row: any, e: any) => {
  e?.stopPropagation()
  emit('onEditTask', row)
}

const onCopyTask = (row: any, e: any) => {
  e?.stopPropagation()
  emit('onCopyTask', row)
}

const onOpenDeleteConfirm = (row: any, e: any) => {
  e?.stopPropagation()
  overlayDrawerData.currentData = row
  showDeleteTaskDialog.value = true
}

const onDeleteReconciliationTask = async () => {
  try {
    deleteTaskLoading.value = true
    await ReconciliationApi.deleteReconciliationTask(
      entityId.value,
      overlayDrawerData.currentData?.reconciliationTaskId || '',
      {
        isRemoveReconciliationAdjustment: agreeRemovedData.value
      }
    )
    ElMessage.warning(t('report.deleting'))
    showDeleteTaskDialog.value = false
    emit('onChangePage', 1)
  } catch (error: any) {
    ElMessage.error(error?.message)
  } finally {
    deleteTaskLoading.value = false
  }
}
</script>

<style lang="scss">
.elv-reconciliation-tasks-list-table {
  height: calc(100% - 69px);
  position: relative;

  .elv-reconciliation-tasks-list-table-box {
    height: calc(100% - 54px);
    position: relative;
  }
}

.elv-reconciliation-tasks-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .elv-reconciliation-tasks-table-header {
    background: #eef4fb;

    .elv-reconciliation-tasks-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &:last-child {
        border-right: 0px;
      }

      &.is-center {
        .cell {
          justify-content: center;
        }
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-reconciliation-tasks-table-row__cell {
    padding: 0;
    height: 42px;
    cursor: pointer;

    &:last-child {
      border-right: 0px;
    }

    &.is-right .cell {
      text-align: right;
    }

    &.is-center .cell {
      text-align: center;
    }

    &.is-number .cell {
      font-family: 'Barlow';
      font-weight: 500;

      .elv-sources-rule-table-row__cell-task-name {
        font-family: 'Plus Jakarta Sans';
      }
    }

    &.is-type .cell {
      .elv-sources-rule-table-row__cell-data-type {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-weight: 400;
          color: #0e0f11;
          height: 23px;
          padding: 0px 12px;
          border-radius: 12px;
          background-color: #f9fafb;
          border: 1px solid #edf0f3;
        }
      }
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    &.elv-reconciliation-tasks-table-row__cell-index .cell {
      color: #aaafb6;
      font-family: 'Barlow';
      font-size: 11px;
      font-style: normal;
    }

    .elv-reconciliation-tasks-table-row__cell-activity {
      font-weight: 500;
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }

    .elv-reconciliation-tasks-table-row__cell-desc {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-wrap: break-word;
      overflow: hidden;
    }

    .elv-reconciliation-tasks-cell__status {
      display: flex;
      height: 22px;
      width: fit-content;
      padding: 0px 8px;
      box-sizing: border-box;
      align-items: center;
      gap: 4px;
      border-radius: 14px;
      border: 1px solid #d0d4d9;
      background: #edf0f3;
      color: #636b75;

      svg {
        margin-right: 4px;
      }

      &.is-approved {
        border-color: #a9dc78;
        background: #f3fceb;
        color: #50970b;
      }
    }

    .elv-reconciliation-tasks-table-row__cell-deleting {
      display: flex;
      align-items: center;

      img {
        width: 14px;
        height: 14px;
        display: block;
        margin-right: 6px;
        animation: loading-rotate 2s linear infinite;
      }
    }

    .elv-reconciliation-tasks-table-row__cell-button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      svg {
        cursor: pointer;
        fill: #838d95;

        &.is-disabled {
          cursor: not-allowed !important;
          fill: #edf0f3 !important;
        }

        &:hover {
          fill: #1e2024;
        }
      }
    }
  }

  .el-table__footer-wrapper {
    .el-table__footer {
      height: 42px;

      .cell.elv-reconciliation-tasks-table-row__total {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .cell.elv-reconciliation-tasks-table-row__amount {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;

        p {
          color: #838d95;
          font-family: 'Barlow';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: none;
        }
      }
    }
  }
}

.elv-reconciliation-tasks-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}
</style>
